<template>
    <v-form>
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <v-select
              :error-messages="typeErrors"
              :label="$t('contactType')"
              @blur="$v.item.type.$touch()"
              @input="$v.item.type.$touch()"
              required
              :items="typeValues"
              v-model="item.type"
              :disabled="disableType"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              :error-messages="valueErrors"
              :label="$t('value')"
              @blur="$v.item.value.$touch()"
              @input="$v.item.value.$touch()"
              v-model="item.value"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-form>
</template>

<script>
  import { validationMixin } from 'vuelidate';
  import has from 'lodash/has';
  import { required, url, email } from 'vuelidate/lib/validators';

  const valueValidation = (value, object) => {
    if(value === "undefined"){
      return false;
    }
    if(object.type.includes('web')){
      return url(value);
    }
    if(object.type.includes('mail')){
      return email(value);
    }
    return true;
  }

  export default {
    name: 'ContactForm',
    mixins: [validationMixin],
    props: {
      values: {
        type: Object,
        required: true
      },
      errors: {
        type: Object,
        default: () => {}
      },

      loading: {
        type: Boolean,
        required: true
      },

      initialValues: {
        type: Object,
        default: () => {}
      },

      handleSubmit: {
        type: Function,
        required: false
      },

      handleReset: {
        type: Function,
        required: false
      },
       disableType: {
        type: Boolean,
        required: false,
        default: false,
      },
      preselectedType: {
        type: String,
        required: false
      },
      allowedContactTypes: {
      type: Array,
      required: false,
    },
    },
    created() {
      if (typeof this.preselectedType !== "undefined") {
        this.item.type = this.preselectedType
      }
    },
    data () {
      return {
        type: null,
        value: null,
        typeValues: this.allowedContactTypes ?? [
          {text: this.$t('phone'), value: 'phone'},
          {text: this.$t('phone_private'), value: 'phone_private'},
          {text: this.$t('phone_business'), value: 'phone_business'},
          {text: this.$t('fax'), value: 'fax'},
          {text: this.$t('fax_private'), value: 'fax_private'},
          {text: this.$t('fax_business'), value: 'fax_business'},
          {text: this.$t('mobile'), value: 'mobile'},
          {text: this.$t('mobile_private'), value: 'mobile_private'},
          {text: this.$t('mobile_business'), value: 'mobile_business'},
          {text: this.$t('mail'), value: 'mail'},
          {text: this.$t('mail_private'), value: 'mail_private'},
          {text: this.$t('mail_business'), value: 'mail_business'},
          {text: this.$t('web'), value: 'web'},
          {text: this.$t('web_private'), value: 'web_private'},
          {text: this.$t('web_business'), value: 'web_business'},
          {text: this.$t('mail_invoice'), value: 'mail_invoice'},
        ],
      };
    },
    computed: {
      item () {
        return this.initialValues || this.values;
      },
      typeErrors () {
        const errors = [];

        if (!this.$v.item.type.$dirty) {
          return errors;
        }

        has(this.violations, 'type') && errors.push(this.violations.type);

        !this.$v.item.type.required && errors.push(this.$t('Field is required'));

        return errors;
      },
      valueErrors () {
        const errors = [];

        if (!this.$v.item.value.$dirty) {
          return errors;
        }

        has(this.violations, 'value') && errors.push(this.violations.value);

        !this.$v.item.value.required && errors.push(this.$t('Field is required'));
        !this.$v.item.value.valueValidation && errors.push(this.$t('Bitte korrekten Wert angeben'));

        return errors;
      },
      violations () {
        return this.errors || {};
      }
    },
    methods: {
      submitItem () {
        if (this.handleSubmit) {
          this.handleSubmit();
        }
      },
      resetItem () {
        if (this.handleReset) {
          this.handleReset();
        }
      }
    },
    validations: {
      item: {
        type: {
          required
        },
        value: {
          required,
          valueValidation
        },
      }
    },
  };
</script>
