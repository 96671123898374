<template>
  <div>
    <template v-if="contactsLoaded">
      <v-data-table
        :headers="contactHeaders"
        :items="contactObjects"
        :loading="isLoadingContacts"
        :loading-text="$t('Loading...')"
        :server-items-length="contactsServerLength"
        class="elevation-1"
        item-key="@id"
        show-select
        v-model="selectedContacts"
      >
        <template slot="item.type" slot-scope="{ item }">
          {{ $t(item["type"]) }}
        </template>
        <template v-slot:item.action="{ item }">
          <ActionCell
            :handle-delete="() => deleteContactHandler(item)"
            :handle-edit="() => contactDialogControl(item['@id'])"
          >
          </ActionCell>
          <ContactDialog
            v-model="showContactDialogForm"
            :contact-item="item"
            :title="$t('EditContact')"
            :handle-updated="updateContactHandler"
            @close="contactDialogControl(item['@id'], true)"
            @delete="deleteContactHandler(item)"
            :key="item.id"
            :disable-type="disableType"
            :preselected-type="preselectedType"
            :allowed-contact-types="allowedContactTypes"
            :tenant-seminar-settings-id="tenantSeminarSettingsId"
          />
        </template>
      </v-data-table>
    </template>
    <template v-else>
      <p>Keine Daten verfügbar</p>
    </template>
    <div class="mt-4">
      <v-btn color="primary" dark @click="contactDialogControl('new')">
        {{ $t("AddContact") }}
      </v-btn>
    </div>
    <ContactDialog
      v-model="showContactDialogForm"
      :contact-item="{}"
      :handle-created="handleCreated"
      :disable-type="disableType"
      :show-handle="showContactDialogForm"
      :title="$t('AddContact')"
      @close="contactDialogControl('new', true)"
      :preselected-type="preselectedType"
      :allowed-contact-types="allowedContactTypes"
      :tenant-seminar-settings-id="tenantSeminarSettingsId"
    />
  </div>
</template>

<script>
import ActionCell from "@/components/ActionCell";
import ContactMixin from "@/mixins/ContactMixin";
import ContactDialog from "@/components/contact/ContactDialog";

export default {
  name: "ContactList",
  mixins: [ContactMixin],
  components: {
    ContactDialog,
    ActionCell,
  },
  props: {
    value: Array,
    contactObjects: {
      type: Array,
      required: true,
    },
    contactsServerLength: {
      type: Number,
      required: true,
    },
    handleCreated: {
      type: Function,
      required: true,
    },
    handleUpdated: {
      type: Function,
      required: false,
    },
    handleDeleted: {
      type: Function,
      required: true,
    },
    disableType: {
      type: Boolean,
      required: false,
      default: false,
    },
    preselectedType: {
      type: String,
      required: false,
    },
    allowedContactTypes: {
      type: Array,
      required: false,
    },
    tenantSeminarSettingsId: {
      type: String,
      required: false,
    }
  },
  data() {
    return {
      isLoadingContacts: false,
      showContactDialogForm: [],
      selectedContacts: [],
      contactHeaders: [
        { text: this.$t("contactType"), value: "type" },
        { text: this.$t("value"), value: "value" },
        {
          text: this.$t("Actions"),
          value: "action",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    contactsLoaded() {
      return this.contactsServerLength === this.contactObjects.length;
    },
  },
  methods: {
    deleteContactHandler(contact) {
      this.deleteContact(contact);
      if (this.handleDeleted) {
        this.handleDeleted(contact);
      }
    },
    updateContactHandler(contact) {
      console.log("updated contact: " + contact.type);
    },
    contactDialogControl(contactId, close = false) {
      if (!close) {
        this.showContactDialogForm.push(contactId);
      } else {
        this.showContactDialogForm.splice(
          this.showContactDialogForm.indexOf(contactId),
          1
        );
      }
    },
  },
};
</script>
