<template>
  <v-dialog
    v-model="showContactDialog"
    persistent
    max-width="600px"
    :key="item['@id']"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <v-card-text>
        <ContactForm
          :errors="violations"
          :values="item"
          :loading="isLoading"
          :ref="getFormRef()"
          :disable-type="disableType"
          :preselected-type="preselectedType"
          :allowed-contact-types="allowedContactTypes"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="handleUpdated"
          @click="confirmDelete = true"
          small
          class="red"
          dark
          color="danger"
        >{{ $t("Delete") }}
        </v-btn>
        <v-btn text @click="showContactDialog = false">{{ $t("Close") }}</v-btn>
        <v-spacer/>
        <v-btn class="mainAccent lighten-3" dark @click="resetForm">{{ $t("Reset") }}</v-btn>
        <v-btn color="primary" @click="sendForm">{{ $t("Submit") }}</v-btn>
      </v-card-actions>
      <ConfirmDelete
        :handle-delete="del"
        :visible="confirmDelete"
        @close="confirmDelete = false"
        v-if="handleUpdated"
      />
    </v-card>
    <Loading :visible="isLoading"/>
  </v-dialog>
</template>

<script>
import {createHelpers} from "vuex-map-fields";
import ContactForm from "./Form";
import NotificationMixin from "../../mixins/NotificationMixin";
import ConfirmDelete from "../ConfirmDelete";
import Loading from "@/components/Loading";
import {mapActions, mapGetters} from "vuex";

const servicePrefix = "Contact";

const {mapFields} = createHelpers({
  getterType: "contact/getField",
  mutationType: "contact/updateField",
});

export default {
  name: "ContactDialog",
  servicePrefix,
  mixins: [NotificationMixin],
  components: {
    Loading,
    ContactForm,
    ConfirmDelete,
  },
  props: {
    value: Array,
    title: {
      type: String,
      required: true,
    },
    handleCreated: {
      type: Function,
      required: false,
    },
    handleUpdated: {
      type: Function,
      required: false,
    },
    contactItem: {
      type: Object,
      required: false,
    },
    disableType: {
      type: Boolean,
      required: false,
      default: false,
    },
    preselectedType: {
      type: String,
      required: false,
    },
    tenantSeminarSettingsId: {
      type: String,
      required: false,
    },
    allowedContactTypes: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      item: {},
      confirmDelete: false,
      createMessage: this.$t("successfullyCreated"),
      updateMessage: this.$t("successfullyUpdated"),
      deleteMessage: this.$t("successfullyDeleted"),
    };
  },
  computed: {
    ...mapFields([
      "error",
      "isLoading",
      "created",
      "deleted",
      "violations",
      "updated",
      "byId",
    ]),
    ...mapGetters("contact", ["find"]),
    formRefName() {
      if (this.handleUpdated) {
        return "updateForm";
      }
      return "createForm";
    },
    formRef() {
      if (this.handleUpdated) {
        return this.$refs.updateForm;
      }
      return this.$refs.createForm;
    },
    showContactDialog: {
      get() {
        return (
          this.value.indexOf(
            typeof this.contactItem["@id"] === "undefined"
              ? "new"
              : this.contactItem["@id"]
          ) > -1
        );
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    ...mapActions("contact", {
      create: "create",
      update: "update",
      resetData: "resetData",
      retrieve: "load",
    }),
    sendForm() {
      if (this.isValidForm()) {
        let model = this.formRef.$v.item.$model;
        if (typeof this.tenantSeminarSettingsId !== "undefined") {
          // hack to handle venues as well
          if (this.tenantSeminarSettingsId.startsWith('/venues')) {
            model["venue"] = this.tenantSeminarSettingsId;
          } else {
            model["tenantSeminarSettings"] = this.tenantSeminarSettingsId;
          }
        }

        if (this.handleUpdated) {
          this.update(model);
        }
        if (this.handleCreated) {
          this.create(model);
        }
      }
    },
    reset() {
      this.formRef.$v.$reset();
    },
    resetForm() {
      this.item = {...this.contactItem};
      this.formRef.$v.$reset();
    },
    del() {
      this.$emit("delete");
      this.$emit("close");
    },
    isValidForm() {
      this.formRef.$v.$touch();
      return !this.formRef.$v.$invalid;
    },
    getFormRef() {
      return this.formRefName;
    },
  },
  watch: {
    created(created) {
      if (!created) {
        return;
      }
      this.showMessage(this.createMessage);
      this.showContactDialog = false;
      if (this.handleCreated) {
        this.handleCreated(this.created);
      }
    },
    updated() {
      this.showMessage(this.updateMessage);
      this.showContactDialog = false;
      if (this.handleUpdated) {
        this.handleUpdated(this.updated);
      }
    },
    error(message) {
      message && this.showError(message);
    },
    deleted() {
      this.showMessage(this.deleteMessage);
    },
  },
  created() {
    this.item = {...this.contactItem};
  },
};
</script>
